import Layout from "../components/Layout"
import styles from "./WikiLayout.module.scss"
import classNames from "classnames"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faEarthAsia, faTimes } from "@fortawesome/free-solid-svg-icons"
import Link from "next/link"

type WikiLayoutProps = {
  nav?: React.ReactNode,
  title?: string,
  children: React.ReactNode
}

const getLinks = () => {
  let links = {
    alluena: "/",
    map: "https://maps.godus.de"
  }

  if (process.env.NODE_ENV === "development") {
    links = {
      alluena: "/alluena",
      map: "/map"
    }
  }

  return links
}

export function WikiLayout({ nav, children, title }: WikiLayoutProps) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const links = getLinks()
  
  return (
    <Layout className={styles["wiki-layout"]} title={title ? `Allüna :: ${title}` : `AllüNa`}>
      <div className={classNames("wiki", styles["wiki"])}>
        <aside className={classNames(styles["wiki-nav"], { [styles["is-collapsed"]]: isCollapsed })}>

          <a href={links.map} className={styles["wiki-floatinglink"]}>
            <FontAwesomeIcon icon={faEarthAsia} />
          </a>

          <div className={styles["wiki-nav__header"]}>
            <a className={classNames(styles["btn-close"])}
               href={links.map}>
              <FontAwesomeIcon icon={faEarthAsia} />
            </a>
            <Link href={links.alluena}>
              <h2>
                AllüNa
                <small className="hidden sm:inline">
                  Das allumfassende, lückenhafte Nachschlagewerk
                </small>
              </h2>
            </Link>
            <button className={classNames(styles["btn-close"])}
                    type="button"
                    onClick={() => setIsCollapsed(true)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <article className={styles["wiki-nav__body"]}>
            {nav}
          </article>

          <footer className={styles["wiki-nav__footer"]}>
            &copy; {new Date().getFullYear()} - Gerald v. Olizius
          </footer>
        </aside>
        <main className={"flex-1 overflow-auto"}>
          <div className={"hidden-md justify-content-between align-items-center w-full fixed bg-white"}>
            <div className={classNames(styles["wiki-nav__header"])}>
              <button className={styles["btn-expand"]}
                      type="button"
                      onClick={() => setIsCollapsed(prevState => !prevState)}>
                <FontAwesomeIcon icon={faBars} />
              </button>
              <h2>
                <span className="visible sm:hidden">
                  AllüNa
                  <br />
                </span>
                <small className="text-muted">
                  Das allumfassende, lückenhafte Nachschlagewerk
                </small>
              </h2>
              <div className={classNames(styles["btn-close"], "invisible")}>
                &nbsp;
              </div>
            </div>
          </div>
          <div className={classNames(styles["wiki-main"], "wiki-page")}>
            {children}
          </div>
        </main>
      </div>
    </Layout>
  )
}
