import styles from "./PageList.module.scss"
import classNames from "classnames"

type PageListProps = {
  pages: WikiPageDescriptor[]
}

const createLink = (entry: WikiPageDescriptor) => {
  if (process.env.NODE_ENV === "development") {
    return `/alluena/${entry.slug}`
  }
  return `/${entry.slug}`
}

export const PageList = ({ pages }: PageListProps) => {
  const undistinctLetters = pages.sort((a, b) => a.title.localeCompare(b.title)).map(p => p.title.toUpperCase().substring(1, 0))
  const letters = Array.from(new Set(undistinctLetters))
    .map(letter => ({
      letter,
      entries: [] as WikiPageDescriptor[]
    }))
    .map(letterEntry => {
      letterEntry.entries = pages.sort((a, b) => a.title.localeCompare(b.title)).filter(p => p.title.toUpperCase().startsWith(letterEntry.letter)) || []
      return letterEntry
    })

  return (
    <div className={styles["page-list"]}>
      <div className={styles["page-list-scroll-container"]}>
        {letters.map(letterEntry => {
          return (
            <div key={letterEntry.letter}>
              <div className={styles["letter"]}>{letterEntry.letter}</div>
              <ul className={styles["page-list-inner"]}>
                {letterEntry.entries.map(entry => {
                  return (
                    <li className={"d-grid gap-2"} key={entry.slug}>
                      <a href={createLink(entry)} className={classNames("btn btn-light btn-sm", styles["btn"])}>
                        {entry.title}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}